<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '450px' }"
    header="FECHA VENCIMIENTO"
    class="p-fluid"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field col-12 md:cold-12 text-center">
      <span class="text-center" style="font-size: 1.2rem; font-weight: bold">
        {{ producto_fechas.descripcion }}
      </span>
      <br />
      <span class="text-center" style="font-size: 1.3rem">
        Cantidad : <strong> {{ producto_fechas.pivot.cantidad ?? 0 }} </strong>
      </span>
    </div>
    <DataTable
      ref="dtFechaVence"
      :value="producto_fechas.fechas_vencimiento"
      editMode="cell"
      @cell-edit-complete="onChangeEditandoCelda"
      class="editable-cells-table p-datatable-sm col-12"
      responsiveLayout="scroll"
      stripedRows
      selectionMode="single click"
      showGridlines
    >
      <Column class="text-right" field="cantidad" header="CANTIDAD">
        <template #body="slotProps">
          {{ convertirNumeroGermanicFormat(slotProps.data.cantidad) }}
        </template>
        <template #editor="{ data }">
          <InputNumber
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            locale="de-ED"
            v-model="data.cantidad"
            autofocus
          />
        </template>
      </Column>
      <Column
        class="text-center"
        field="fecha_vence"
        header="FECHA VENCIMIENTO"
      >
        <template #body="slotProps">
          {{ slotProps.data.fecha_vence.toLocaleDateString() }}
        </template>
        <template #editor="{ data }">
          <Calendar
            v-model="data.fecha_vence"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
        </template>
      </Column>
      <Column class="text-right" field="lote" header="LOTE">
        <template #body="slotProps">
          {{ slotProps.data.lote }}
        </template>
        <template #editor="{ data }">
          <InputText v-model="data.lote" />
        </template>
      </Column>
      <Column>
        <template #header>
          <Button
            :disabled="!boton_agregar"
            class="p-button-rounded p-button-outlined"
            icon="pi pi-plus"
            v-tooltip.top="'Agregar nueva fecha'"
            @click="agregarFechaVence"
          />
        </template>
        <template #body="data">
          <Button
            class="p-button-rounded p-button-outlined p-button-danger"
            icon="pi pi-trash"
            v-tooltip.top="'Quitar fecha'"
            @click="eliminarFechaVence(data)"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="registrarFechaVence"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
export default {
  name: "FechaVence",
  emits: ["closeModal"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      enviado: false,
      activarModal: this.show,
      producto_fechas: this.producto,
      boton_agregar: true,
    };
  },
  methods: {
    eliminarFechaVence(data) {
      this.$confirm.require({
        message: `¿Está seguro de quitar la fecha?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.producto_fechas.fechas_vencimiento.splice(data.index, 1);
          this.verificarCantidad();
        },
      });
    },
    onChangeEditandoCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    registrarFechaVence() {
      this.enviado = true;
      if (this.verificarCantidad()) {
        this.$toast.add({
          severity: "success",
          summary: "Éxito",
          detail: "Se registraron las fechas de vencimiento",
          life: 3000,
        });
        this.enviado = false; // Ajuste realizado para establecer enviado en false después de la validación exitosa
        this.closeModal();
      } else {
        this.enviado = false; // Asegurarse de establecer enviado en false incluso si la validación falla
      }
    },

    verificarCantidad() {
      let cantidad = 0;
      this.producto_fechas.fechas_vencimiento.forEach((item) => {
        if (item.cantidad <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad debe ser mayor a 0",
            life: 3000,
          });
          this.boton_agregar = false; // Desactivar el botón
          return false;
        }
        cantidad += item.cantidad;
      });
      if (cantidad !== this.producto_fechas.pivot.cantidad) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La cantidad no coincide con las cantidades de fechas",
          life: 3000,
        });
        this.boton_agregar = false; // Desactivar el botón
        return false;
      }

      this.boton_agregar = true; // Activar el botón si la validación es exitosa
      return true;
    },

    agregarFechaVence() {
      this.producto_fechas.fechas_vencimiento.push({
        cantidad: this.producto_fechas.pivot.cantidad,
        fecha_vence: new Date(),
        lote: null,
      });
    },
  },
  watch: {
    show(value) {
      this.activarModal = value;
    },
    producto(value) {
      this.producto_fechas = value;
      /* this.producto_fechas.fechas_vencimiento.push({
        cantidad: this.producto_fechas.pivot.cantidad,
        fecha_vence: new Date(),
        lote: null,
      }); */
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/demo/badges.scss";
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
